import { TRootState } from '../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { TNewMatrixState, TSelectedHeadersCells } from '@/reducers/entities/newMatrix.reducer.types';
import { NodeId } from '@/serverapi/api';
import { HeaderType } from '@/modules/Matrix/NewMatrixEditor/NewMatrix.types';

const matrixStateSelector = (state: TRootState): TNewMatrixState => state.newMatrix;
export namespace NewMatrixSelectors {
    export const getSelectedHeaderCells = (nodeId: NodeId) =>
        createSelector<TRootState, TNewMatrixState, TSelectedHeadersCells>(
            matrixStateSelector,
            (state) =>
                state[nodeId.repositoryId]?.[nodeId.id]?.selectedHeaderCells || { type: HeaderType.column, ids: [] },
        );

    export const getSelectedCells = (nodeId: NodeId) =>
        createSelector<TRootState, TNewMatrixState, string[]>(
            matrixStateSelector,
            (state) => state[nodeId.repositoryId]?.[nodeId.id]?.selectedCells || [],
        );

    export const isMatrixUnsaved = (nodeId: NodeId) =>
        createSelector<TRootState, TNewMatrixState, boolean>(
            matrixStateSelector,
            (state) => state[nodeId.repositoryId]?.[nodeId.id]?.unsaved,
        );
}
