import { ObjectDefinitionImpl } from '../../../models/bpm/bpm-model-impl';
import { TCurrentUserProfile } from '../../../reducers/userProfile.reducer.types';
import { MatrixLane } from '../../../serverapi/api';

export type THeaderResizeData = {
    isResizing: boolean;
    direction?: HeaderResizeDirections;
};

export type TColResizeData = {
    id?: string;
    isResizing: boolean;
};

export type TRowResizeData = {
    id?: string;
    isResizing: boolean;
};

export type TMainHeaderLanes = {
    showTooltip: boolean;
    lanes: JSX.Element[];
    topPadding: number;
    sidePadding: number;
};

export enum EdgeSymbolType {
    custom = 'CUSTOM',
    icon = 'ICON',
    userIcon = 'USER_ICON',
}

export enum IconType {
    plus = 'PLUS',
    minus = 'MINUS',
    check = 'CHECK',
    cross = 'CROSS',
}

export enum HeaderType {
    row = 'row',
    column = 'column',
}

export enum HeaderCellsContextActions {
    levelUp = 'levelUp',
    levelDown = 'levelDown',
}

export enum CellsContextActions {
    symbolsSettings = 'symbolsSettings',
    createDefinition = 'createDefinition',
}

export enum HeaderResizeDirections {
    horizontal = 'horizontal',
    vertical = 'vertical',
    both = 'both',
}

type TAclRightType = 'delete' | 'read' | 'update';

export type TAclRightsType =
    | [TAclRightType]
    | [TAclRightType, TAclRightType]
    | [TAclRightType, TAclRightType, TAclRightType];

export type TCheckForbiddenByProfile = {
    selectedHeaders: MatrixLane[];
    userProfile: TCurrentUserProfile | undefined;
    objectDefinitions: {
        [id: string]: ObjectDefinitionImpl;
    };
    rights: TAclRightsType;
};
