import { HeaderType } from '@/modules/Matrix/NewMatrixEditor/NewMatrix.types';
import {
    MATRIX_SELECT_HEADER_CELLS,
    MATRIX_CLEAR_SELECTED_CELLS,
    MATRIX_SELECT_CELLS,
    MATRIX_PAST_OBJECTS,
    NEW_MATRIX_SAVE_REQUEST,
    REFRESH_NEW_MATRIX,
    DELETE_OBJECT_HEADERS,
} from '@/actionsTypes/entities/newMatrix.actionTypes';
import {
    TDeleteMatrixObjectHeaders,
    TMatrixClearSelectedCellsAction,
    TMatrixPastObjectsAction,
    TMatrixSelectCellsAction,
    TMatrixSelectHeaderCellsAction,
    TNewMatrixSaveRequestAction,
    TRefreshNewMatrixAction,
} from './newMatrix.actions.types';
import { NodeId, Node } from '@/serverapi/api';

export const matrixSelectHeaderCells = (
    nodeId: NodeId,
    type: HeaderType,
    ids: string[],
): TMatrixSelectHeaderCellsAction => ({
    type: MATRIX_SELECT_HEADER_CELLS,
    payload: {
        nodeId,
        type,
        ids,
    },
});

export const matrixSelectCells = (nodeId: NodeId, ids: string[]): TMatrixSelectCellsAction => ({
    type: MATRIX_SELECT_CELLS,
    payload: {
        nodeId,
        ids,
    },
});

export const matrixClearSelectedCells = (nodeId: NodeId): TMatrixClearSelectedCellsAction => ({
    type: MATRIX_CLEAR_SELECTED_CELLS,
    payload: {
        nodeId,
    },
});

export const matrixPastObjects = (nodeId: NodeId): TMatrixPastObjectsAction => ({
    type: MATRIX_PAST_OBJECTS,
    payload: {
        nodeId,
    },
});

export const newMatrixSaveRequest = (nodeId: NodeId): TNewMatrixSaveRequestAction => ({
    type: NEW_MATRIX_SAVE_REQUEST,
    payload: {
        nodeId,
    },
});

export const refreshNewMatrix = (nodeId: NodeId): TRefreshNewMatrixAction => ({
    type: REFRESH_NEW_MATRIX,
    payload: {
        nodeId,
    },
});

export const deleteMatrixObjectHeaders = (nodes: Node[]): TDeleteMatrixObjectHeaders => ({
    type: DELETE_OBJECT_HEADERS,
    payload: {
        nodes,
    },
});
