export const MATRIX_PAST_OBJECTS = 'MATRIX_PAST_OBJECTS';

export const MATRIX_SELECT_HEADER_CELLS = 'MATRIX_SELECT_HEADER_CELLS';

export const MATRIX_SELECT_CELLS = 'MATRIX_SELECT_CELLS';

export const MATRIX_CLEAR_SELECTED_CELLS = 'MATRIX_CLEAR_SELECTED_CELLS';

export const NEW_MATRIX_SAVE_REQUEST = 'NEW_MATRIX_SAVE_REQUEST';

export const REFRESH_NEW_MATRIX = 'REFRESH_NEW_MATRIX';

export const DELETE_OBJECT_HEADERS = 'DELETE_OBJECT_HEADERS';
